import React, { useState } from "react";
import {ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { Transition } from '@headlessui/react'

const Testimonials = function({data, bg, text}){

    const [current, setCurrent] = useState(0);
    const [currentBuffer, setCurrentBuffer] = useState(0)
    const length = data.testimonials.length;
  
    const nextSlide = () => {
        setCurrent(-1)
        setCurrentBuffer(current === length - 1 ? 0 : current + 1)
        setTimeout(() => {
            setCurrent(current === length - 1 ? 0 : current + 1); 
        }, 600);
    };
  
    const prevSlide = () => {
        setCurrent(-1)
        setCurrentBuffer(current === 0 ? length - 1 : current - 1)
        setTimeout(() => {
            setCurrent(current === 0 ? length - 1 : current - 1);
        }, 600);
    };

    const setSlide = (index) => {
        setCurrent(-1)
        setCurrentBuffer(index)
        setTimeout(() => {
            setCurrent(index)
        }, 600);
    }

    return (

        <div className={(bg ? bg : "bg-primary" ) + (text ? " " + text : " text-white" ) +  " w-full h-96 relative"}>

            <ChevronLeftIcon onClick={prevSlide}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 text-gray-400 transition hover:text-white h-10 w-10 md:h-14 md:w-14 cursor-pointer"></ChevronLeftIcon>

            <svg className={(text ? text : " text-secondary" ) + " fill-current absolute top-2 md:top-4 left-1/2 transform -translate-x-1/2"} xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 0 24 24" width="80px"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></svg>

            <div className="w-4/5 max-w-5xl mx-auto flex flex-col items-center justify-center h-full">

                {data.testimonials.map((item, index) => (
                        <Transition
                            key={index}
                            show={index === current}
                            enter="transition transform duration-500"
                            enterFrom="opacity-0 translate-y-6"
                            enterTo="opacity-100"
                            leave="transition transform duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0 translate-y-6"
                        >
                            <div className="h2 italic text-center mb-4">"{item.quote}"</div>
                            <div className="font-bold text-center">{item.from}</div>
                        </Transition>
                    )
                )}

            </div>

            <div className="flex gap-1 absolute bottom-2 md:bottom-4 left-1/2 transform -translate-x-1/2">
            {data.testimonials.map((item, index) => (
                    <div key={index} onClick={() => setSlide(index)}
                    className={(index === currentBuffer ? "bg-white" : "bg-gray-400")
                        + " rounded-full hover:bg-white h-2 w-2 cursor-pointer"}
                    >
                    </div>  
                )
            )}
            </div>

            <ChevronRightIcon onClick={nextSlide}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 text-gray-400 transition hover:text-white h-10 w-10 md:h-14 md:w-14 cursor-pointer"></ChevronRightIcon>

        </div>

    )
}

export default Testimonials