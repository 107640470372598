import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const BigImages = function ({ data }) {
	return (
		<div className="w-full grid grid-cols-1 lg:grid-cols-2">
			{data?.images?.map((item, index) => {
				return (
					<div
						key={index}
						className={
							(index === 0 ? "lg:col-span-2 " : "") + "grid w-full h-96"
						}
					>
						<GatsbyImage
							alt=""
							style={{
								gridArea: "1/1",
							}}
							objectPosition={item.objectPosition}
							image={getImage(item.imageNode)}
							className="h-96 w-full object-cover"
							imgClassName="w-full object-cover"
							loading="lazy"
						/>

						<div
							className={
								(index === 0
									? "bg-gray-200 bg-opacity-30"
									: index % 2 === 0
									? "bg-[#00f] bg-opacity-30"
									: "bg-secondary-500 bg-opacity-20") +
								" grid place-items-center relative lg:hidden"
							}
							style={{
								gridArea: "1/1",
								textShadow: "2px 2px black, 0 0 3px black",
							}}
						>
							<div className="h2 text-white font-bold pt-5">{item.text}</div>
						</div>

						<div
							className={
								(index === 0
									? "bg-gray-600 bg-opacity-30"
									: index % 4 === 2 || index % 4 === 3
									? "bg-[#00f] bg-opacity-30"
									: "bg-secondary-500 bg-opacity-20") +
								" place-items-center relative hidden lg:grid"
							}
							style={{
								gridArea: "1/1",
								textShadow: "2px 2px black, 0 0 3px black",
							}}
						>
							<div className="h2 text-white font-bold pt-5">{item.text}</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default BigImages
