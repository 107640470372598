import {
	faArrowCircleUp,
	faCamera,
	faThumbsUp,
	faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Fade from "react-reveal/Fade"

const data = {
	text: ["Was kann gutes internes Recruiting bewirken?"],
	multiple: [
		[
			{
				text: ["Image ist nicht alles, aber wenn …"],
				richText: [
					"<ul><li>… Sie immer mehr <strong>zufriedene Mitarbeiter</strong> beschäftigen wird sich das <strong>rumsprechen</strong> und Sie werden <strong>zu einem der attraktivsten Arbeitgeber in Ihrer Branche/Region.</strong></li><li>… die Bewerber gut behandelt werden und der Bewerbungsprozess stimmig ist, werden es die Bewerber Ihnen <strong>danken und weitererzählen</strong>! Wenn nicht, leider auch.</li><li>… der Ruf bei Bewerbern und Mitarbeitern schon mal gut ist, öffnen sich viele unerwartete Türen – von Empfehlungen bis hin zu Neukunden.</li></ul>",
				],
			},
			{
				text: ["Höherer Gewinn"],
				richText: [
					"<ul><li>Sie verdienen mehr, da Ihre <strong>Stellen</strong>, die in Ihrer <strong>Wertschöpfungskette</strong> einen positiven Beitrag leisten, auch besetzt werden.</li><li>Exzellente Personalbeschaffung sorgt auch dafür, dass Sie mehr Topperformer für Ihr Unternehmen gewinnen können &gt; Topperformer sorgen für außergewöhnliche Ergebnisse – zum Bsp. im Vertrieb.</li><li>Selbstredend wird Ihr Gewinn alleine dadurch erhöht, wenn die o. g. Kostenreduzierungen zum Tragen kommen.</li></ul>",
				],
			},
			{
				text: ["Glückliche Mitarbeiter – wenn …"],
				richText: [
					"<ul><li>… die neuen Mitarbeiter zum Mitarbeiterstamm passen, wirkt sich das auf das <strong>Betriebsklima</strong> aus.</li><li>… Mitarbeiter zufrieden sind, sind sie auch <strong>gesünder und somit weniger krank.</strong></li><li>Ein zufriedener Mitarbeiter ist <strong>loyaler als ein unzufriedener</strong> – er wird Ihr Unternehmen nicht verlassen, nur weil der Wettbewerb mit einer Gehaltserhöhung lockt.</li></ul>",
				],
			},
			{
				text: ["Kostenreduzierung"],
				richText: [
					"<ul><li>Sie sparen Geld ein – jede <strong>unbesetzte Stelle in Ihrer Wertschöpfungskette</strong> kostet Sie Geld.</li><li>Durch mehr eigene Bewerber – d. h. somit auch weniger Personalvermittlungs-Honorare an externe Personaldienstleister.</li><li>Wenn Sie besser recruiten, reduziert sich dadurch Ihre Fluktuation – Sie kennen die Kosten, die eine Mitarbeiterfluktuation verursacht.</li></ul>",
				],
			},
		],
	],
}

const AdvIcons = function () {
	return (
		<div className="bg-blue-50 w-full">
			<div className="sectionwidth sectionpadding">
				<h2 className="h1_5 mb-8 font-bold text-primary text-center w-full">
					{data.text[0]}
				</h2>

				<div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
					{data.multiple[0].map((item, index) => {
						let icon
						switch (index) {
							case 0:
								icon = faCamera
								break
							case 1:
								icon = faArrowCircleUp

								break
							case 2:
								icon = faThumbsUp

								break
							case 3:
								icon = faArrowCircleDown

								break
							default:
								icon = faCamera
						}
						return (
							<Fade left key={index} delay={index * 200}>
								<div className="flex gap-8">
									<FontAwesomeIcon
										icon={icon}
										size="3x"
										className="text-secondary"
									/>
									<div>
										<h3 className="h3 text-primary font-bold mb-2">
											{item.text}
										</h3>
										<div
											dangerouslySetInnerHTML={{ __html: item.richText }}
										></div>
									</div>
								</div>
							</Fade>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default AdvIcons
