import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/hero"
import BigImages from "../components/sections/bigImages"
import LastSection from "../components/sections/lastSection"
import { attachImageEdgesRecursive } from "../utils/functions"
import Tabs from "../components/sections/Tabs"
import NumbersWithText from "../components/sections/NumbersWithText"
import LinksIR from "../components/sections/LinksIR"
import AdvIcons from "../components/sections/AdvIcons"
import Testimonials from "../components/sections/testimonials"
import LongTextImageText from "../components/sections/LongTextImageText"
import Numbers from "../components/sections/Numbers"

const index = {
	seo: {
		title: "Internes Recruiting optimieren - so geht´s!",
		description:
			"Wir optimieren internes Recruiting in KMU`s: ✔️ 30 Branchen ✔️ 20 Erfahrung in der Recruitingberatung ✔️ 46 Jahre Erfahrung im Recruiting",
	},
	hero: {
		h1: "Internes Recruiting optimieren bedeutet:",
		checklist: [
			"Massive Senkung der Kosten für die externe Personalbeschaffung",
			"Schneller & einfacher neue Mitarbeiter finden",
			"Mitarbeiterfluktuation deutlich senken",
			"Externes Recruiting harmonisieren",
		],
		text: "Mehr Erfolg im Recruiting für kleine und mittelständische Unternehmen!",
		btnText: "Termin buchen",
		btnLink: "/termin/#termin",
		image: "startseite_hero.jpg",
	},
	bigImages: {
		images: [
			{
				image: "startseite_big_images_1.jpg",
				text: "Gutes internes Recruiting sorgt für …",
			},
			{
				image: "startseite_big_images_2.jpg",
				text: "… messbare Ergebnisse",
			},
			{
				image: "startseite_big_images_3.jpg",
				text: "… mehr Bewerber",
			},
			{
				image: "startseite_big_images_4.jpg",
				text: "… Senkung der Fluktuation",
			},
			{
				image: "startseite_big_images_5.jpg",
				text: "… Erhöhung der Outperformer-Quote",
			},
		],
	},
	lastSection: {
		image: "startseite_last_section.jpg",
		title: "Recruiten Sie zukünftig schneller, motivierter & günstiger:",
	},
}

const testimonials = {
	testimonials: [
		{
			quote:
				"Durch die Neuausrichtung unseres internen Recruitings konnten wir die qualifizierten Bewerber innerhalb von 5 Wochen auf über 50 % steigern.",
			from: "Geschäftsführer, IT-Systemhaus",
		},
		{
			quote:
				"Die Recruitingkosten haben sich in den ersten drei Monaten um 30 % gesenkt – es hat sich bereits im ersten Monat amortisiert!",
			from: "Personalleiterin, Maschinenbau",
		},
		{
			quote:
				"Kein Agenturgequassel wie bei vielen Employer Branding Dienstleistern – nachvollziehbares und zielführendes Konzept für die wirklich relevanten Themen des Recruitings.",
			from: "Ressortleiter Recruiting, Dienstleistungsholding",
		},
	],
}

const IndexPage = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					images: allFile(filter: { relativePath: { regex: "/startseite/" } }) {
						edges {
							node {
								relativePath
								childImageSharp {
									gatsbyImageData(placeholder: TRACED_SVG)
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				attachImageEdgesRecursive(data.images.edges, index)

				return (
					<Layout>
						<Seo
							title={index.seo?.title}
							description={index.seo?.description}
						/>
						<Hero data={index.hero} />
						<Tabs />
						<NumbersWithText />
						<LinksIR />
						<AdvIcons />
						<Testimonials data={testimonials} />
						<LongTextImageText />
						<BigImages data={index.bigImages} />
						<Numbers />
						<LastSection data={index.lastSection} />
					</Layout>
				)
			}}
		/>
	)
}

export default IndexPage
