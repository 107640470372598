import { faBolt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tab } from "@headlessui/react"
import React, { Fragment } from "react"
import { useState } from "react"

const data = {
	text: [
		"Recruitingprobleme von kleinen und mittelständischen Unternehmen …",
		"… lassen sich lösen – wenn das interne Recruiting richtig positioniert ist & mit den richtigen Recruitern besetzt ist.",
	],
	richText: [
		"Richtig ist, dass es für viele kleinere und mittlere Unternehmen schwieriger geworden ist, zeitnah genügend und vor allem passende Bewerber zu finden. Die Ursachen sind vielfältig und unterscheiden sich von Branche zu Berufs- bzw. Funktionsbereichen:",
		"Den demographischen Wandel und den generellen Fachkräftemangel können Sie nicht ändern – Sie können aber proaktiv entgegensteuern und sich so im War of Talents erfolgreich behaupten.",
	],
	multiple: [
		[
			{
				text: ["Chefsache"],
				richText: [
					"Solange das Thema internes Recruiting nicht zur Chefsache erklärt wird, wird sich erfahrungsgemäß auch nichts ändern – Sie müssen den ersten Schritt machen; er ist einfach, wenn der Wille zur Veränderung gegeben ist.",
				],
			},
			{
				text: ["Digitalisierung"],
				richText: [
					"Die Möglichkeiten, die sich im Recruiting durch die Digitalisierung eröffnen, sind groß – werden aber nicht oder falsch genutzt und stattdessen rennt man „HR-Mode-Trends“ hinterher.",
				],
			},
			{
				text: ["Vertriebsgen"],
				richText: [
					"Recruiten heißt verkaufen – verkaufen bedeutet andere Menschen zu überzeugen. Beginnend bei der telefonischen Bewerberannahme bis hin zum Abschluss des Arbeitsvertrages – wenn das Vertriebsgen nicht gegeben ist, muss es durch Seminare entwickelt werden oder eine andere Person muss sich um das Recruiting kümmern.",
				],
			},
			{
				text: ["Ressourcen"],
				richText: [
					"In vielen kleineren und mittleren Unternehmen muss die Personalabteilung viel tun – von Mitarbeiter-Ein – und Austritten, über Gehaltsabrechnungen erstellen, hin zur Betreuung der Auszubildenden, sich um die Personalentwicklung kümmern und „nebenbei“ noch Stellen zeitnah mit Top-Mitarbeitern besetzen.",
				],
			},
			{
				text: ["Kosten"],
				richText: [
					"Es wird viel Geld für kostenpflichtige Stellenanzeigen ausgegeben, die wenig bis gar keinen Erfolg bringen. Dann werden meistens parallel noch Personaldienstleister eingeschaltet und die Personalrekrutierungskosten steigen noch mehr.",
				],
			},
			{
				text: ["Motivation"],
				richText: [
					"Was haben Ihre Recruiter davon, mal abgesehen vom Grundgehalt, wenn unbesetzte Stellen zeitnah mit den richtigen Mitarbeitern besetzt werden?",
				],
			},
			{
				text: ["Ziele"],
				richText: [
					"Konkrete Ziele und Maßnahmenpläne (KPI`s) fehlen häufig – die meisten Recruiter wissen nicht, was eine unbesetzte Stelle ihr Unternehmen Geld kostet bzw. dadurch verloren geht.",
				],
			},
		],
	],
}

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const Tabs = function () {
	return (
		<div className="bg-white w-full">
			<div className="sectionwidth sectionpadding">
				<h2 className="h1_5 mb-4 font-bold text-primary text-center w-full">
					{data.text[0]}
				</h2>
				<div dangerouslySetInnerHTML={{ __html: data.richText[0] }}></div>
				<Tab.Group
					as="div"
					className="my-8 bg-white overflow-hidden shadow rounded-lg"
				>
					<div className="border-b border-gray-200">
						<Tab.List
							as="nav"
							className="-mb-px flex flex-col md:flex-row md:flex-wrap bg-gray-50"
						>
							{data.multiple[0].map((tab, index) => (
								<Tab key={index} as={Fragment}>
									{({ selected }) => (
										<button
											className={classNames(
												selected
													? "border-primary-500 text-primary-600"
													: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
												"flex-grow py-1 md:py-4 px-1 text-center border-b-2 font-semibold flex justify-center items-center focus:outline-none"
											)}
											aria-current={tab.current ? "page" : undefined}
										>
											<FontAwesomeIcon
												icon={faBolt}
												className="text-secondary mr-4"
												size="lg"
											/>
											<div>{tab.text[0]}</div>
										</button>
									)}
								</Tab>
							))}
						</Tab.List>
						<nav aria-label="Tabs"></nav>
					</div>
					<Tab.Panels></Tab.Panels>
					{data.multiple[0].map((tab, index) => (
						<Tab.Panel key={index} className="focus:outline-none">
							<div
								className="px-4 py-5 sm:p-6"
								dangerouslySetInnerHTML={{
									__html: tab.richText,
								}}
							></div>
						</Tab.Panel>
					))}
				</Tab.Group>
				<div className="my-8 bg-white overflow-hidden shadow rounded-lg"></div>
				<div dangerouslySetInnerHTML={{ __html: data.richText[1] }}></div>
				<h2 className="h2 mt-4 font-bold text-primary text-center w-full">
					{data.text[1]}
				</h2>
			</div>
		</div>
	)
}

export default Tabs
