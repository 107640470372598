import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useState } from "react"
import loadable from "@loadable/component"
const AngebotForm = loadable(() => import("../Forms"), {
	resolveComponent: (components) => components.AngebotForm,
})

const LastSection = function ({ data }) {
	const [angebot, setangebot] = useState(false)
	return (
		<div className="w-full">
			<div className="grid w-full" style={{ height: "800px" }}>
				<GatsbyImage
					alt=""
					style={{
						gridArea: "1/1",
						width: "100%",
					}}
					imgStyle={{
						width: "100%",
					}}
					objectPosition="70% 20%"
					image={getImage(data.imageNode)}
					loading="lazy"
				/>
				<div
					className="grid  relative place-items-start bg-[#00f] bg-opacity-30"
					style={{
						gridArea: "1/1",
						textShadow: "2px 2px black, 0 0 3px black",
					}}
				>
					<div className="w-full sectionwidth text-white text-center py-40">
						<div className="h2 font-bold mb-8">{data.title}</div>
						<div className="flex justify-center gap-8 ">
							<AnchorLink
								to="/termin/#termin"
								className="btn btn-secondary textShadow-none"
							>
								Termin buchen
							</AnchorLink>
							<AnchorLink
								to="#angebot"
								onAnchorLinkClick={() => setangebot(true)}
								className="btn btn-primary textShadow-none"
							>
								Angebot anfordern
							</AnchorLink>
						</div>
					</div>
				</div>
			</div>
			<div
				className={"bg-gray-100 w-full " + (angebot ? "" : "hidden")}
				id="angebot"
			>
				<div className="sectionwidth sectionpadding">
					{angebot && <AngebotForm />}
				</div>
			</div>
		</div>
	)
}

export default LastSection
