import React from "react"
import loadable from "@loadable/component"
import Observer from "../Observer"
const CountingNumber = loadable(() => import("../CountingNumber"))

const data = {
	text: ["Wir geben unsere Recruitingexpertise an Sie weiter:"],
	multiple: [
		[
			{
				text: ["Jahre ..."],
				number: 46,
				richText: [
					"<strong>… Erfahrung&nbsp;</strong>im Recruiting! Sie profitieren von unserem Insiderwissen aus der rekrutierungsintensivsten Branche überhaupt: der Personaldienstleistungsbranche; Top-Personaldienstleister haben und machen wir noch immer erfolgreicher.",
				],
			},
			{
				text: ["Werktage ..."],
				number: 7,
				richText: [
					"<b>… bis</b> <b>zum</b> <b>Projektabschluss.&nbsp;</b>Durch unser gewonnenes Know-how und die Spezialisierung sind wir in der Lage, alle unsere angebotenen Recruitinglösungen innerhalb von 7 Werktagen für Sie erfolgreich abzuarbeiten.",
				],
			},
			{
				text: ["Branchen ..."],
				number: 30,
				richText: [
					"<b>… und </b><b>es</b><b> werden </b><b>mehr</b>, die von unseren Recruitinglösungen profitieren und Wettbewerbsvorteile gewinnen. Vom Industrieunternehmen bis hin zum Wirtschaftsprüfer spricht jede Zielgruppe eine eigene Sprache und muss entsprechend anders angesprochen werden.",
				],
			},
		],
	],
}

const NumbersWithText = function () {
	return (
		<div className="bg-blue-50 w-full">
			<div className="sectionwidth sectionpadding">
				<h2 className="h1_5 mb-8 font-bold text-primary text-center w-full">
					{data.text[0]}
				</h2>

				<div className="flex flex-col lg:flex-row gap-8">
					{data.multiple[0].map((item, index) => (
						<div
							key={index}
							className="bg-white overflow-hidden shadow sm:rounded-lg flex-1 transform transition-transform hover:scale-105"
						>
							<div className="px-4 py-5 sm:p-6 flex flex-col justify-center">
								<div className="h1 text-primary font-bold text-center mb-1">
									<Observer>
										{(isVisible) =>
											isVisible ? <CountingNumber number={item.number} /> : 0
										}
									</Observer>
								</div>
								<div className="h2 text-primary font-bold text-center mb-8">
									{item.text}
								</div>
								<div
									className="text-center"
									dangerouslySetInnerHTML={{ __html: item.richText }}
								></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default NumbersWithText
