import React from "react"
import loadable from "@loadable/component"
import Observer from "../Observer"
const CountingNumber = loadable(() => import("../CountingNumber"))

const data = {
	multiple: [
		[
			{
				text: ["Jahre Erfahrung in der Recruitingoptimierung"],
				number: 20,
			},
			{
				text: ["Kreativ, leidenschaftlich & zielorientiert"],
				number: 1.0,
			},
			{
				text: ["Unverständnis für Unternehmen, die auf Recruiting „pfeifen“"],
				number: 0.999,
			},
		],
	],
}

const Numbers = function () {
	return (
		<div className="bg-blue-50 w-full">
			<div className="sectionwidth sectionpadding">
				<div className="flex flex-col lg:flex-row gap-8">
					{data.multiple[0].map((item, index) => (
						<h2 key={index} className="flex-grow text-center">
							<span
								className={
									"h1 mb-1 block" +
									(index === 0 ? " text-primary" : "") +
									(index === 1 ? " text-secondary" : "")
								}
							>
								<Observer>
									{(isVisible) =>
										isVisible ? (
											<CountingNumber
												number={
													item.number <= 1 ? item.number * 100 : item.number
												}
												suffix={item.number <= 1 ? " %" : ""}
												decimals={item.number <= 1 ? 1 : 0}
											/>
										) : (
											0
										)
									}
								</Observer>
							</span>
							<span className="h3 font-bold">{item.text}</span>
						</h2>
					))}
				</div>
			</div>
		</div>
	)
}

export default Numbers
