import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Fade from "react-reveal/Fade"

const data = {
	text: [
		"Recruiting wird zur Chefsache – dann sollten Sie uns kontaktieren!",
		"Machen die richtigen Recruiter, die richtigen Dinge richtig?",
		"Welche Recruitinglösungen helfen tatsächlich und können innerhalb von sieben Werktagen erfolgreich umgesetzt werden?",
		"Wie kann ein Unternehmen in seiner Branche den Recruitingweg finden, der die gewünschten Ziele erreicht?",
	],
	richText: [
		"<strong>Ihr Recruitment …</strong></p><ul><li>… ist mit hohen Kosten verbunden?</li><li>… führt trotzdem dazu, dass Sie auf externe Personaldienstleister zurückgreifen müssen und sorgt so nochmals für zusätzliche Kosten?</li><li>… rekrutiert nicht genügend qualifizierte, zum Unternehmen und ins Budget passende Bewerber?</li><li>… ist Ihrer Meinung nach demotiviert, unstrukturiert und kommt nicht richtig weiter?</li></ul><p>Die gute Nachricht für Sie: zum einen haben die meisten Unternehmen diese Probleme, zum anderen sind Sie ja schon auf der richtigen Seite. Denn wir haben nur eins im Kopf:</p><p>Ihr <b>Recruitment erfolgreich zu machen! </b></p><p>Unser Team hat über <b>45 Jahre Erfahrung im Recruiting</b> und über <b>zwei Jahrzehnte Erfahrung in der Recruitingoptimierung. </b>Deswegen befassen wir uns seit Jahren fast ausschließlich mit&nbsp;<strong><span style='color: #f58201;'>diesen drei Kernfragen:</span></strong>",
	],
}

const LongTextImageText = function () {
	return (
		<div className="bg-blue-50 w-full">
			<div className="sectionwidth sectionpadding">
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
					<div className="lg:col-span-2">
						<h2 className="h1_5 mb-4 font-bold text-primary w-full">
							{data.text[0]}
						</h2>
						<div className="lg:hidden">
							<Fade right>
								<StaticImage
									className="border-2 border-secondary rounded-lg shadow-md"
									src="../../images/startseite_long.jpg"
									alt="internes-recruiting-ihr-unternehmenserfolg-basiert-auf-den-richtigen-mitarbeitern"
									placeholder="tracedSVG"
									height={470}
								/>
							</Fade>
						</div>
						<div
							className="prose"
							dangerouslySetInnerHTML={{ __html: data.richText[0] }}
						></div>
					</div>
					<div className="hidden lg:block">
						<Fade right>
							<StaticImage
								className="border-2 border-secondary rounded-lg shadow-lg"
								src="../../images/startseite_long.jpg"
								alt="internes-recruiting-ihr-unternehmenserfolg-basiert-auf-den-richtigen-mitarbeitern"
								placeholder="tracedSVG"
								height={470}
							/>
						</Fade>
					</div>
				</div>
				<div className="flex flex-col lg:flex-row justify-between mt-4 lg:mt-16 border-t border-gray-500 gap-8 mb-4">
					{[...Array(3)].map((item, index) => (
						<Fade key={index} left delay={index * 300}>
							<div className="flex flex-col items-center justify-start flex-1">
								<div className="h-20 w-20 flex justify-center items-center text-4xl bg-secondary text-white font-bold rounded-full mt-4 lg:-mt-10">
									{index + 1}
								</div>

								<h3 className="h3 text-primary my-2 font-bold">
									{data.text[index + 1]}
								</h3>
							</div>
						</Fade>
					))}
				</div>
			</div>
		</div>
	)
}

export default LongTextImageText
