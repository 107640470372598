import {
	faChild,
	faHandshake,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const data = {
	text: ["Unsere Lösungen für Ihren Recruitingerfolg"],
	richText: [
		"Jede Herausforderung lässt sich im internen Recruiting innerhalb von einer Woche lösen – das ist unser Maßstab.",
		"So erhalten Sie zukünftig <strong>schneller &amp; mehr passende Bewerber</strong>. Unsere Analyse zeigt Ihnen Lösungswege für eine erfolgreichere Personalgewinnung auf.",
		"So lösen Sie Ihre Recruitingthemen nachhaltig, erhalten <strong>neue</strong> <strong>Impulse</strong> und bringen Ihr Recruitingteam durch <strong>Schulungen</strong> und <strong>Workshops</strong> nach vorne.",
		"So wird die <strong>Führung</strong> Ihres <strong>Recruitingteams</strong> sachlich, motivierend und zielführend. Wenn Sie einen <strong>Recruiter suchen</strong>, finden wir diesen.",
	],
}

const LinksIR = function () {
	return (
		<div className="bg-white w-full">
			<div className="sectionwidth sectionpadding">
				<h2 className="h1_5 mb-4 font-bold text-primary text-center w-full">
					{data.text[0]}
				</h2>
				<div
					className="mb-8 text-center"
					dangerouslySetInnerHTML={{ __html: data.richText[0] }}
				></div>

				<div className="flex flex-col md:flex-row -mx-2 sm:mx-0">
					<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary text-white transform transition-transform hover:z-10 hover:scale-105">
						<FontAwesomeIcon icon={faChild} size="3x" />
						<h3 className="h3 font-bold">Bewerbergewinnung</h3>
						<div dangerouslySetInnerHTML={{ __html: data.richText[1] }}></div>
						<div className="flex flex-col gap-2 items-center">
							<a
								href="/karriere-website-kosten-angebot"
								className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
							>
								Karriere-Website
							</a>
							<a
								href="/e-recruiting-analyse-kosten-sparen"
								className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
							>
								E-Recruiting-Analyse
							</a>
							<a
								href="/active-sourcing-konzept-zum-erfolg"
								className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
							>
								Active-Sourcing
							</a>
						</div>
					</div>

					<div className="py-8 px-3 text-center space-y-4 flex-grow bg-secondary-800 text-white transform transition-transform hover:z-10 hover:scale-105">
						<FontAwesomeIcon icon={faHandshake} size="3x" />
						<h3 className="h3 font-bold">Recruiting Beratung</h3>
						<div dangerouslySetInnerHTML={{ __html: data.richText[2] }}></div>
						<div className="flex flex-col gap-2 items-center">
							<a
								href="/recruiting-strategie-kmu"
								className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
							>
								Recruiting Strategie
							</a>
							<a
								href="/recruiting-impulse-neue-ideen"
								className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
							>
								Recruiting Impulse
							</a>
							<a
								href="/recruiting-seminare-fuer-recruiter"
								className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
							>
								Recruiting Seminare
							</a>
						</div>
					</div>

					<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary-900 text-white transform transition-transform hover:z-10 hover:scale-105">
						<FontAwesomeIcon icon={faUserTie} size="3x" />
						<h3 className="h3 font-bold">Recruiting in Aktion</h3>
						<div dangerouslySetInnerHTML={{ __html: data.richText[2] }}></div>
						<div className="flex flex-col gap-2 items-center">
							<a
								href="/recruiting-kpi-motivation"
								className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
							>
								Recruiting KPI´s & Motivation
							</a>
							<a
								href="/recruiter-vermittlung-festanstellung"
								className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
							>
								Recruitervermittlung
							</a>
							<a
								href="/recruiter-mieten-und-rpo-laeuft"
								className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
							>
								Recruiter mieten
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LinksIR
